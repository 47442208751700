@import "global";
.staff {
  padding-top: 130px;
  .row {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  &__column {
    @include flex-grid-column(4);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @include breakpoint(1100 down) {
    &__column {
      @include flex-grid-column(6);
    }
  }
  @include breakpoint(phone down) {
    padding-top: 65px;
    padding-bottom: 20px;
    &__column {
      @include flex-grid-column(12, 20);
    }
    .row {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }
  }
  @include breakpoint(tiny down) {
    &__column {
      @include flex-grid-column(12, 15);
    }
  }
}
